import '@animxyz/core'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue3 from 'bootstrap-vue-3'
import VCollapse from 'v-collapse-it'
import { Collapse } from 'vue-collapsed'
import VueEasyLightbox from 'vue-easy-lightbox'
import VueAnimXYZ from '@animxyz/vue3'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import SweetModal from 'sweet-modal-vue-3'
import { inject } from '@vercel/analytics'

inject()

createApp(App)
  .component('Collapse', Collapse)
  .use(router)
  .use(SweetModal)
  .use(VueEasyLightbox)
  .use(BootstrapVue3)
  .use(VCollapse)
  .use(VueAnimXYZ)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyDaNFkLay5xUbMy54YLKmOZdusXwzKCAYI'
    }
  })
  .mount('#app')
