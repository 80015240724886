<template>
  <div class="app-main_layout">
    <RouterView />
  </div>
</template>

<style lang="scss">
.app-main {
  &_layout {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &_body {
    flex: 1;
  }
}
</style>

